<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
-->

<template>
	<div>
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg'); height: 200px"></div>
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						<a-avatar :size="74" shape="square" :src="userInfo.avatar" />
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{{ userInfo.nickname }}</h4>
							<p v-if="tabsId === 1">积分：{{ userInfo.usable || 0 }}</p>
							<p v-if="tabsId === 2 && userInfo.is_vip === 1">VIP到期时间：{{ userInfo.vip_expiretime_text }}</p>
							<div v-if="tabsId === 3 && userInfo.reseller">
								<p>{{ userInfo.reseller.reseller_json.name }}</p>
								<p>到期时间：{{ userInfo.reseller.expiretime_text }}</p>
							</div>
						</div>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
						<a-radio-group v-model="tabsId" size="small">
							<a-radio-button :value="item.id" v-for="(item, index) in tabsList" :key="'tab_' + item.id">{{ item.title }}</a-radio-button>
						</a-radio-group>
					</a-col>
				</a-row>
			</template>
		</a-card>

		<div v-if="tabsId === 1">
			<CardRechargeIntegral></CardRechargeIntegral>
		</div>
		<div v-if="tabsId === 2">
			<CardRechargeMember></CardRechargeMember>
		</div>
		<div v-if="tabsId === 3">
			<CardRechargeDealer></CardRechargeDealer>
		</div>
	</div>
</template>

<script>
	import CardRechargeIntegral from "../components/Cards/CardRechargeIntegral.vue"
	import CardRechargeMember from "../components/Cards/CardRechargeMember.vue"
	import CardRechargeDealer from "../components/Cards/CardRechargeDealer.vue"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		components: {
			CardRechargeIntegral,
			CardRechargeMember,
			CardRechargeDealer
		},
		data() {
			return {
				tabsId: 1,
				tabsList: [
					{ id: 1, title: "积分充值" },
					{ id: 2, title: "开通会员" },
					{ id: 3, title: "开通经销商" }
				]
			}
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"])
		},
		watch: {},
		created() {
			this.getUserInfo()
			// this.getStatisticInfo()
		},
		methods: {
			...mapActions("user", ["getUserInfo", "getStatisticInfo"])
		}
	}
</script>

<style lang="scss"></style>
